/** Styles for Learn more section */

.learn-more-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  z-index: 1;
}

.learn-more-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column {
  margin-bottom: 20px;
}

.column h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.column p {
  font-size: 1em;
  line-height: 1.5;
}

/** Styles for Mobile */

@media (max-width: 768px) {
  .learn-more-container {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .learn-more-content {
    align-items: center;
    padding: 15px;
  }
  .column h2 {
    text-align: center;
  }
  .column p {
    text-align: center;
  }
}
