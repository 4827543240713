/** Styles for Instagram element*/

.instagram-column {
    flex: 1;
    margin-right: 20px; 
    padding: 30px;
    max-width: 540px; 
    margin: 0 auto; 
  }



/** Styles for Mobile */

@media (max-width: 768px) {
    .instagram-column {
      margin-right: 20px;
      padding: 16px;
      max-width: 100%;      
    }
}