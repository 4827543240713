@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: "Roboto Bold";
}

body {
  margin: 0;
  background: #d8d8d8;
}

main {
  padding: 0;
  max-width: 100vw;
  margin: 0 auto;
}

