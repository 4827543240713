/** Styles for Home Page*/

.home-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: url("../assets/Logo/doradorock.webp");
  background-size: cover;
  background-position: center;
  background-color: #000;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.home-header {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: white;
  width: 100vw;
  font-size: 2.5rem;
  top: 15%;
  margin-bottom: 20px;
}

.home-container span {
  display: flex;
  align-items: center;
  font-size: 4.5rem;
}

.button-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 50px;
  margin-top: 50px;
  z-index: 2;
}

.button-container button {
  font-size: 16px;
  background-color: transparent;
  border: 1px white solid;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 45px;
  width: 205px;
}

.button-container a {
  text-decoration: none;
  color: white;
}

.button-container button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.outline-button {
  display: inline-block;
  padding: 12px 24px;
  border: 2px solid white;
  border-radius: 4px;
  color: white;
  font-family: "SpaceMono Bold", sans-serif;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: pre-line;
}

.outline-button:hover {
  background-color: white;
  color: black;
}

/** Mobile Styles for home page*/

@media (max-width: 768px) {
  .home-container {
    background-attachment: scroll;
  }

  .button-container {
    top: 60%;
    z-index: 2;
  }

  .home-container span {
    display: flex;
    align-items: center;
    font-size: 2rem;
  }
  .home-header {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: white;
    width: 100vw;
    font-size: 2rem;
    margin-bottom: 20px;
    padding-top: 60px;
  }

}
