/** Styles for Header */

header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: transparent;
  padding: 0px 10px;
}

header.scrolled {
  background-color: rgba(0, 0, 0, 0.5);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 15px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

header a {
  text-decoration: none;
  color: white;
}

header a.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

header nav {
  display: flex;
  gap: 15px;
}

.mobile-menu-toggle {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.mobile-menu {
  display: none;
}



.desktop-menu {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.desktop-menu a {
  text-decoration: none;
  color: white;
  position: relative;
  padding-bottom: 5px;
}

.desktop-menu a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.desktop-menu a:hover::before {
  background-color: white;
}

/** Mobile Styles For Header */

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .header-right {
    display: none;
  }

  .header-text-wrap {
    white-space: pre-wrap;
    text-align: center;
    margin: 0 auto;
    font-size: 2.3rem;
    letter-spacing: 0;
  }
  .mobile-menu-toggle {
    display: block;
  }
  .mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 999;
    padding: 1rem;
  }

  .mobile-menu a {
    display: block;
    text-align: center;
    padding: 1rem;
    font-size: 2.5rem;
    text-decoration: none;
    color: #333;
  }
  .mobile-menu-toggle .fa-bars {
    color: white;
    font-size: 1.5rem;
  }
  .mobile-menu-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
}
